<template>
  <div>
    <QUERY @selDis="selDis"></QUERY>

    <div class="banner" v-if="bannerUrl">
      <img :src="`${bannerUrl}`" width="1400px" style="cursor: pointer" alt="" />
    </div>
    <!-- 陈列照列表 start-->
    <div class="goodsList" style="margin-top: 20px;">
      <template v-for="(item, index) in displayImageList">
        <template v-if="item.is_show_at_choose_goods === 0">
          <div class="goodsList_div" :key="index">
            <div class="goodsList_div_imgdiv">
              <div class="goodsList_div_imgdiv_goodsImg">
                <el-image :src="item.imagesSrc" :preview-src-list="[item.imagesSrc]" style="width: 318px;height: 318px;"  alt=""></el-image>
              </div>
            </div>
            <div class="goodsList_div_bottom">
              <div class="prod_title clickDisplayImg"  @click="clickDisplayImg(item)">                 
                <p style="color: #000000; font-size: 15px; margin-right: 5px">{{ $t("Plan details") }}</p>
              </div>
              <div class="prod_title">
                <div style="font-size: 14px; color: #000000;">
                  {{ $t("Qty") }}:{{ item.bind_number }}
                  {{ $t("Subtotal") }}:{{ item.bind_total }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
      </div>
    <!-- 陈列照列表 end-->

    <Footer @setPages="setPages" msg="right" ref="footPagesR"></Footer>
    <div class="goodsList" v-show="goodsList.length > 0">
      <div class="goodsList_div" v-for="(item, index) in goodsList" :key="index">
        <div class="goodsList_div_imgdiv">

          <div class="goodsList_div_imgdiv_icon">
            <img v-for="(fname,index) in item.iconArr" height="30" :src="fname" :key="index" alt="">
          </div>

          <div class="goodsList_div_imgdiv_goodsImg">
            <img :src="`http://hwimg.xmvogue.com/thumb/${item.item_no}.jpg?x-oss-process=style/440`"
                 style="width: 318px;height: 318px;" @click="clickImg(item)" alt="" />
          </div>

        </div>
        <div class="goodsList_div_bottom">
          <div class="prod_title">
            <span style="color: #000000; font-size: 14px; margin-right: 5px">{{ item.item_no }}</span>
            <i class="el-icon-document-copy" v-clipboard:copy="item.item_no" v-clipboard:success="onCopy"
               v-clipboard:error="onError"></i>
          </div>
          <div class="prod_title">
            <el-tooltip :content="item.item_en" placement="bottom" effect="light">
              <p style="color: #000000; font-size: 15px; margin-right: 5px">
                {{ (lang === "zh-CN" ? item.item_name : item.item_en) | cutstr }}
              </p>
            </el-tooltip>

          </div>
          <div class="prod_title" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
            <div style="font-size: 14px; color: #000000;">{{ $t("PURCHASE PRICE") }}:</div>
            <div style="font-size: 14px; color: #d92524; margin-left: 5px;">
              {{ $t("Price symbol") }}{{ item.base_price.toFixed(2) }}
            </div>
          </div>
          <div class="prod_title" v-if="userInfo.pid === 0 || userInfo.show_price === 1">
            <div style="font-size: 14px; color: #000000; margin-left: 5px">
              {{ $t("RETAIL PRICE") }}:
            </div>
            <div style="font-size: 14px; color: #d92524; margin-left: 5px">
              {{ $t("Price symbol") }}{{ item.sale_price.toFixed(2) }}
            </div>
          </div>

          <div class="change_num">
            <el-input-number v-model="item.addNum" :min="item.purchase_spec" :max="item.us_num" :step="item.purchase_spec" />
          </div>
          <div class="prod_handle">
            <div class="prod_handle_add">
              <p class="prod_handle_title">
                <a @click="addCarts(item)">{{ $t("Add to Cart") }}</a>
                <a v-if="item.user_cart_info"
                   style="margin-left: 8px">[{{ item.user_cart_info.num }}]</a>
                <a v-else style="margin-left: 8px">[0]</a>
                <i class="el-icon-delete" style="margin-left: 8px" @click="delteCartGoods(item.item_no)"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="notFindGoods" v-show="goodsList.length === 0">
      {{ $t("No products of this type found") }}
    </div>
    <Footer @setPages="setPages" ref="footPagesB"></Footer>
  </div>
</template>
<script>
import { GetSeriesGoodsList, GetSeriesDisplayList } from "@/api/goods/series.js";

import common from "@/common/mixins/common.js";
import { AddToCartByGoods } from "@/api/carts/carts.js";
import Footer from "@/views/web/allGoods/footer/index.vue";
import QUERY from "@/views/web/ipSeries/content/query.vue";
import banner from "@/api/web/home/banner";
// import NumberInput from "@/components/NumberInput.vue";
import { DeleteGoods2Carts } from "@/api/carts/carts";

export default {
  mixins: [common],
  components: {
    // NumberInput,
    Footer,
    QUERY,
  },
  data() {
    return {
      num: [],
      goodsList: [],
      typeList: [],
      queryData: {
        typeId: "",
        page: 1,
        pageSize: 16,
      },
      lang: this.$i18n.locale,
      bannerUrl: "",
      displayImageList: [],//陈列照列表
      // 陈列照查询条件
      queryDisplayData: {
        series_id: "",
        page: 1,
        pageSize: 16,
      },
      QUERY_ipId: 0,
      currentItem:{},
      userInfo:{},
    };
  },
  watch: {
    "queryData.typeId"() {
      //alert(this.queryData.typeId)
      this.$route.query.id=this.queryData.typeId;
      this.getData();
      this.handleGetBanner();
      this.getDisplayData();
    },
  },
  created() {
    // 从系列的陈列照界面点击其它系列时
    this.jumpOtherSeries();
    this.getData();
    this.handleGetBanner();
    const user = window.sessionStorage.getItem("webUser");
    const userArr = JSON.parse(user);
    this.userInfo = userArr.user_info;
  },
  methods: {
    // 从系列的陈列照界面点击其它系列时
    jumpOtherSeries() {
      const typeId=this.$route.query.id;
      if (typeId) {
        this.queryData.typeId=parseInt(typeId);
        this.QUERY_ipId=parseInt(typeId); //高亮菜单栏
      }
    },
    //复制商品条码
    onCopy: function() {
      this.$message.success("Product barcode copied to clipboard");
    },
    onError: function() {
      this.$message.success("Copy failed");
    },
    //设置活动选项
    selDis(val) {
      this.queryData.typeId=val.id;
      this.queryData.page=1;
      //this.getData()
    },
    //删除购物车处理
    delteCartGoods(sku) {
      DeleteGoods2Carts(sku).then(() => {
        this.$message.success("successfully deleted");
        this.getData();
        this.$emit("initCart");
      });
    },
    //加入购物车
    addCarts(row) {
      if (row.addNum % row.purchase_spec !== 0) {
        this.$message.error(
          this.$t("Please change in multiples of specifications"),
        );
        this.$nextTick(() => {
          row.addNum=row.purchase_spec;
        });
      } else {
        AddToCartByGoods(row.item_no, row.addNum, row.purchase_spec).then(() => {
            this.getData();
            this.$emit("initCart");
        });
      }

    },
    //设置翻页
    setPages(page) {
      this.queryData.page=page;
      this.getData();
    },
    //获取列表产品
    getData() {
      let queryData={};

      var query=this.$route.query;
      if (query.id > 0) {
        queryData.typeId=query.id;
      } else {
        queryData.typeId=this.queryData.typeId;
      }
      //queryData.typeId = this.queryData.typeId

      queryData.page=this.queryData.page;
      queryData.pageSize=this.queryData.pageSize;

      GetSeriesGoodsList(queryData).then((res) => {
        let resData=res.data;

        //设置分页
        this.$refs.footPagesB.setPages(
          resData.data.page,
          resData.data.total,
          resData.data.page_size,
        );
        //设置分页
        this.$refs.footPagesR.setPages(
          resData.data.page,
          resData.data.total,
          resData.data.page_size,
        );

        this.goodsList=this.filterGoods(resData.data.data);
      }).catch(() => {
        this.$message.error("Unable to find product"); //无法找到产品
      });
    },
    //设定一个添加购物车的数据，用来绑定添加购物车的数量
    filterGoods(goodsData) {
      let resData=goodsData;
      for (let i=0; i < resData.length; i++) {
        resData[i].addNum=resData[i].purchase_spec;
        resData[i].iconArr=resData[i].file_type_icon_list.split(",");
      }
      return resData;
    },
    //跳转到详情页
    clickImg(row) {
      let {
        href,
      }=this.$router.resolve({
        path: "/web/goodsDetail",
        query: {
          id: row.item_no,
        },
      });
      window.open(href);
    },
    handleGetBanner() {
      const typeid=this.queryData.typeId;
      if (typeid > 0) {
        banner.getBanner(typeid).then((res) => {
          const data=res.data.data;
          if (data.length > 0) {
            this.bannerUrl=data[0].imageUrl;
          } else {
            this.bannerUrl="";
          }
        });
      }
    },

    //======================================陈列照============================
    //跳转到陈列照列表
    clickDisplayImg(row) {
      this.$router.push({
        path: "/web/displayGoodsList",
        query: {
          id: row.id,
          typeId: this.queryData.typeId,
        },
      });
    },

    // 重置陈列照列表数据
    resetDisplaayImages() {
      this.displayImageList=[];
    },

    //获取列表产品
    getDisplayData() {
      let queryDisplayData={};

      queryDisplayData.series_id=this.queryData.typeId;// 系列关联的陈列分类
      queryDisplayData.page=this.queryDisplayData.page;
      queryDisplayData.pageSize=this.queryDisplayData.pageSize;

      // let that = this
      GetSeriesDisplayList(queryDisplayData).then((res) => {
        let resData=res.data;
        // 赋值变量
        setTimeout(() => {
          this.resetDisplaayImages();
          this.displayImageList=resData.data;
        }, 500);


      }).catch(() => {
        this.$message.error("Unable to find product"); //无法找到产品
      });
    },

    // updateNumber(val){
    //   this.currentItem.addNum = val
    // }
  },
};
</script>
<style lang="scss" scoped>
.goodsList {
  width: 1400px;
  margin: 0 auto;
  background-color: #f5f8fa;
  display: grid;
  grid-template-columns: 320px 320px 320px 320px;
  grid-row-gap: 32px;
  grid-column-gap: 32px;

  &_div {
    // background: yellow;
    border: 1px solid #ccc;

    &_imgdiv {
      min-height: 320px;
      position: relative;

      //图标
      &_icon {
        position: absolute;
        top: 2px;
        right: 2px;

        img {
          border: 1px solid #ccc;
          margin-left: 3px;
        }
      }

      //商品图标
      &_goodsImg {
        cursor: pointer;
        text-align: center;
        border-bottom: 1px solid #ccc;
        // min-height: 320px;
        width: 318px;
        height: 319px;
      }
    }

    //文字按钮样式
    &_bottom {
      text-align: center;
      margin-top: 5px;
    }

  }
}

.prod_title {
  width: 320px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.change_num {
  //width: 356px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prod_handle {
  //width: 356px;
  height: 40px;
  background-color: #ddedf0;
  margin-top: 5px;

  &_add {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  &_title {
    color: #218da0;
    font-size: 16px;
    border-bottom: 1px solid #218da0;
  }
}

.notFindGoods {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

.banner {
  text-align: center;
  padding-top: 20px;
}

.clickDisplayImg{
  cursor: pointer;
}
.clickDisplayImg >p {
  color: #218da0 !important;
  font-size: 16px !important;
  border-bottom: 1px solid #218da0;
}

</style>
